@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: url(../public/fonts/Montserrat-VariableFont_wght.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&family=Lato:wght@300;400;700;900&family=Montserrat:wght@100;200;300;400;600;700;800;900&display=swap");

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.career-input-error {
  background-color: rgba(255, 59, 88, 0.2470588235) !important;
  color: #000;
  font-family: "Montserrat", sans-serif;
}

.service-container {
  clip-path: polygon(0 8%, 100% 0%, 100% 92%, 0% 100%);
}
.development-container {
  display: flex;
  flex-direction: column;
}
.development-wrapper-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}
.development-wrapper-odd {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 3rem;
}
.logistic-wrapper-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}
.logistic-wrapper-odd {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 3rem;
}
.logistic-container {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}
.embroidery-container {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}
.embroidery-wrapper-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}

.embroidery-wrapper-odd {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 3rem;
}
.image-card img {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}
.zoom-effect-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
}
.card-image:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
  width: 100%;
  height: inherit;
  transition: all 0.5s;
  overflow: hidden;
  position: relative;
}

.strip-wrapper {
  z-index: 1000;
}

/* #scroll_button {
  z-index: 999999;
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.list {
  list-style: disc;
}
.creative-background {
  background: transparent linear-gradient(304deg, #f3d488 0%, #765525 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.about-banner {
  background: #252525 0% 0% no-repeat padding-box;
}
.about-section-background {
  background: transparent linear-gradient(306deg, #e4c27a 0%, #bc9e5a 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.App-loader-image {
  width: 70%;
  height: auto;
  animation: pulse-animation 2s infinite ease-in-out;
}
@media screen and (max-width: 1024px) {
  .App-loader-image {
    width: 80%;
  }
  .development-wrapper-even {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .development-wrapper-odd {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .logistic-wrapper-even {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .logistic-wrapper-odd {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .embroidery-wrapper-even {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .embroidery-wrapper-odd {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
}

/* .react-multi-carousel-track {
  transform: translate3d(-1706px, -19px, 0px);
} */

@media screen and (min-width: 768px) {
  .service-container {
    clip-path: polygon(0 8%, 100% 0%, 100% 92%, 0% 100%);
  }
  .menu-mobile {
    fill: #fff;
  }
}
@media screen and (max-width: 576px) {
  .logistic-container {
    gap: 3rem;
  }
  .menu-mobile {
    fill: #fff;
  }
}
@media screen and (max-width: 375px) {
  .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .menu-mobile {
    fill: #fff;
  }
}
@media screen and (min-width: 300px) {
  .service-container {
    clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
  }

  .menu-mobile {
    fill: #fff;
  }
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
