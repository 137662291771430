.App {
  text-align: center;
}
* {
  @apply scroll-smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grow {
  animation: grow 2s ease;
}
@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/* In your footer */
/* .bg-carbon-fiber {
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 1)
    ),
    linear-gradient(
      45deg,
      black 25%,
      transparent 25%,
      transparent 75%,
      black 75%,
      black
    ),
    linear-gradient(
      45deg,
      black 25%,
      transparent 25%,
      transparent 75%,
      black 75%,
      black
    ),
    linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;
} */

.bg-carbon-fiber {
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(
      to right,
      rgb(1, 10, 35),
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgb(1, 10, 35)
    ),
    linear-gradient(
      45deg,
      #030944 20%,
      transparent 20%,
      transparent 75%,
      #030944 55%,
      #030944
    ),
    linear-gradient(
      45deg,
      #030944 20%,
      transparent 20%,
      transparent 75%,
      #030944 55%,
      #030944
    ),
    linear-gradient(to bottom, rgb(0, 14, 70), rgb(0, 22, 86));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;
}

/* 030944 */